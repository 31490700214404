.section {
  padding: 4rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.sectionTitle {
  font-size: 2.5rem;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.sectionDescription {
  text-align: center;
  font-size: 1.2rem;
  color: #34495e;
  max-width: 800px;
  margin: 0 auto 3rem;
}

.featureGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.featureItem {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.featureItem:hover {
  transform: translateY(-5px);
}

.featureIcon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.featureTitle {
  font-size: 1.5rem;
  color: #3498db;
  margin-bottom: 1rem;
}

.ctaContainer {
  text-align: center;
}

.cta {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .sectionTitle {
    font-size: 2rem;
  }

  .sectionDescription {
    font-size: 1rem;
  }

  .featureTitle {
    font-size: 1.3rem;
  }
}
