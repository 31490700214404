.character-relationship-graph {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  z-index: 10;
}

.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: var(--color-primary-green);
  color: var(--color-white);
  flex-shrink: 0;
}

.graph-header h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.graph-controls {
  display: flex;
  gap: 12px;
}

.close-graph-btn {
  background-color: var(--color-white);
  color: var(--color-primary-green);
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.close-graph-btn:hover {
  background-color: var(--color-gray-100);
}

.graph-container {
  position: relative;
  flex: 1;
  min-height: 0; /* This is crucial for flexbox to work properly */
  overflow: hidden;
}

/* Force the vis-network container to fill its parent */
.graph-container .vis-network {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
}

/* Force the canvas to fill the vis-network container */
.graph-container canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
}

.graph-legend {
  padding: 16px 24px;
  background-color: var(--color-gray-50);
  border-top: 1px solid var(--color-gray-200);
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.legend-section {
  flex: 1;
  min-width: 250px;
}

.graph-legend h3 {
  margin: 0 0 12px 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--color-gray-800);
}

.legend-items {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.legend-color {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
}

.legend-line {
  width: 24px;
  height: 4px;
  border-radius: 2px;
}

.legend-item span {
  font-size: 0.9rem;
  color: var(--color-gray-700);
}

.legend-empty-message {
  font-size: 0.9rem;
  color: var(--color-gray-500);
  font-style: italic;
  padding: 4px 0;
}

/* Custom tooltip styles for network graph */
div.vis-tooltip {
  position: absolute;
  visibility: hidden;
  padding: 8px 12px;
  white-space: nowrap;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.9rem;
  color: var(--color-gray-800);
  background-color: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

/* Make highlighted nodes and edges more visible */
.vis-network .vis-node.highlighted {
  border-width: 3px !important;
  border-color: var(--color-white) !important;
  box-shadow: 0 0 10px var(--color-primary-green) !important;
}

.vis-network .vis-edge.highlighted {
  width: 3px !important;
  filter: drop-shadow(0 0 5px var(--color-primary-green)) !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .graph-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .graph-controls {
    width: 100%;
  }

  .close-graph-btn {
    width: 100%;
  }

  .legend-items {
    flex-direction: column;
    gap: 8px;
  }
}
