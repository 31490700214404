/* src/pages/AdminDashboard.module.css */
.statisticsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.statisticCard {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statisticCard h3 {
  margin-top: 0;
  color: #333;
}

.statisticCard p {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 0;
}

.error {
  color: #dc3545;
  font-weight: bold;
}
