.container {
  width: 100%;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subtitle {
  margin-bottom: 1rem;
  color: #333;
}

.label {
  display: block;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.textarea {
  width: 100%;
  min-height: 120px;
  padding: 0.8rem;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  resize: vertical;
}

.textarea:focus {
  outline: none;
  border-color: #3498db;
}

.button {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover:not(:disabled) {
  background-color: #2980b9;
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.success {
  color: #27ae60;
  text-align: center;
  margin-top: 0.5rem;
}

.error {
  color: #e74c3c;
  text-align: center;
  margin-top: 0.5rem;
}

@media (max-width: 600px) {
  .button {
    width: 100%;
  }
}
