/* src/pages/Feedback/FeedbackPage.module.css */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.title {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  text-align: center;
}

.description {
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.6;
}
