.series-form-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.series-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-content h1 {
  color: var(--color-primary-green);
  margin: 0;
  font-size: 2rem;
}

.edit-toggle {
  padding: 0.5rem 1rem;
  border: 2px solid var(--color-primary-green);
  background: none;
  color: var(--color-primary-green);
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.edit-toggle:hover {
  background-color: var(--color-primary-green);
  color: white;
}

.edit-toggle.active {
  background-color: var(--color-primary-green);
  color: white;
}

.back-button {
  padding: 0.5rem 1rem;
  background-color: var(--color-gray-200);
  color: var(--color-gray-700);
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.back-button:hover {
  background-color: var(--color-gray-300);
}

/* Form elements */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--color-gray-700);
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-control:focus {
  border-color: var(--color-primary-green);
  outline: none;
}

.form-control:disabled {
  background-color: var(--color-white);
  cursor: not-allowed;
}

textarea.form-control {
  resize: vertical;
  min-height: 100px;
}

/* Tabs */
.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-gray-300);
  margin-bottom: 1.5rem;
}

.tab {
  padding: 0.75rem 1.25rem;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  font-weight: 500;
  color: var(--color-gray-600);
  transition: all 0.2s;
}

.tab:hover {
  color: var(--color-primary-green);
}

.tab.active {
  color: var(--color-primary-green);
  border-bottom-color: var(--color-primary-green);
}

.tab-panel {
  padding: 1rem 0;
}

/* Tags */
.tag-input-container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.tag-input {
  flex-grow: 1;
}

.add-tag-btn {
  background-color: var(--color-primary-green);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-tag-btn:hover {
  background-color: var(--color-secondary-green);
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-accent-gold);
  color: var(--color-gray-800);
  border-radius: 16px;
  padding: 0.3rem 0.75rem;
  font-size: 0.85rem;
}

.remove-tag {
  background: none;
  border: none;
  color: var(--color-gray-700);
  margin-left: 0.25rem;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.remove-tag:hover {
  color: var(--color-error);
}

/* Image preview */
.image-preview {
  margin-top: 1rem;
  max-width: 300px;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: auto;
  display: block;
}

/* Form actions */
.form-footer {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--color-gray-200);
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.cancel-button {
  padding: 0.75rem 1.5rem;
  background-color: var(--color-gray-200);
  color: var(--color-gray-700);
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-button:hover {
  background-color: var(--color-gray-300);
}

.submit-button {
  padding: 0.75rem 1.5rem;
  background-color: var(--color-primary-green);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover:not(:disabled) {
  background-color: var(--color-secondary-green);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Character Management */
.create-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.create-btn {
  padding: 0.75rem 1.5rem;
  background-color: var(--color-primary-green);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-btn:hover {
  background-color: var(--color-secondary-green);
}

.character-filters {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.search-input {
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  min-width: 250px;
}

.role-filter {
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  background-color: white;
}

.characters-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.character-card {
  border: 1px solid var(--color-gray-200);
  border-radius: 8px;
  overflow: hidden;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  background-color: white;
  cursor: pointer;
}

.character-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.character-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  background-color: var(--color-gray-100);
}

.character-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.character-info {
  padding: 1.25rem;
}

.character-info h3 {
  margin: 0 0 0.5rem;
  color: var(--color-gray-800);
  font-size: 1.25rem;
}

.character-aliases {
  font-size: 0.9rem;
  color: var(--color-gray-600);
  margin-bottom: 0.5rem;
}

.character-role {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: var(--color-accent-gold);
  color: var(--color-gray-800);
  border-radius: 16px;
  font-size: 0.85rem;
  margin-bottom: 0.75rem;
}

.character-description {
  color: var(--color-gray-700);
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Location styles */
.locations-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.location-card {
  background: var(--color-gray-50);
  border: 1px solid var(--color-gray-200);
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.location-card.depth-0 {
  border: 1px solid var(--color-gray-300);
  background: white;
}

.location-card.depth-1 {
  background: var(--color-gray-50);
}

.location-card.depth-2 {
  background: var(--color-gray-100);
}

.location-card.depth-3 {
  background: var(--color-gray-150);
}

.location-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.location-info {
  padding: 0.5rem;
}

.location-type {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  background: var(--color-primary-green);
  color: white;
  border-radius: 4px;
  font-size: 0.875rem;
  margin: 0.5rem 0;
}

.location-description {
  color: var(--color-gray-600);
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

.nested-locations {
  margin-left: 1.5rem;
  margin-top: 1rem;
  padding-left: 1rem;
  border-left: 2px solid var(--color-gray-200);
}

.location-filters {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.location-filters .search-input {
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  min-width: 250px;
}

.location-filters .type-filter {
  padding: 0.5rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  background-color: white;
}

.location-card {
  background-color: white;
  border: 1px solid var(--color-gray-300);
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}

.location-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.location-info h3 {
  color: var(--color-primary-green);
  margin: 0 0 0.5rem 0;
  font-size: 1.25rem;
}

.location-type {
  display: inline-block;
  background-color: var(--color-gray-100);
  color: var(--color-gray-700);
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.parent-location {
  color: var(--color-gray-600);
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
}

.location-description {
  color: var(--color-gray-700);
  font-size: 0.9375rem;
  line-height: 1.5;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Character relationship graph wrapper */
.character-relationship-graph-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: var(--color-white);
}

/* Book management styles */
.book-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.book-management-header h2 {
  color: var(--color-primary-green);
  margin: 0;
}

.book-filters {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.books-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.books-section {
  background-color: var(--color-gray-50);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.books-section h3 {
  color: var(--color-secondary-green);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-bottom: 2px solid var(--color-accent-gold);
  padding-bottom: 0.5rem;
}

.books-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.book-card {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}

.book-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.book-info {
  margin-bottom: 1rem;
}

.book-info h4 {
  color: var(--color-gray-800);
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.book-meta {
  color: var(--color-gray-600);
  font-size: 0.9rem;
}

.no-books-message {
  color: var(--color-gray-600);
  font-style: italic;
  padding: 1rem;
  text-align: center;
  background-color: var(--color-gray-100);
  border-radius: 4px;
}

.add-book-btn {
  background-color: var(--color-primary-green);
  color: var(--color-white);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
}

.add-book-btn:hover {
  background-color: var(--color-secondary-green);
}

.add-book-btn:disabled {
  background-color: var(--color-gray-400);
  cursor: not-allowed;
}

.remove-book-btn {
  background-color: var(--color-white);
  color: var(--color-error);
  border: 1px solid var(--color-error);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
}

.remove-book-btn:hover {
  background-color: var(--color-error-light);
}

.remove-book-btn:disabled {
  color: var(--color-gray-500);
  border-color: var(--color-gray-400);
  background-color: var(--color-gray-100);
  cursor: not-allowed;
}
