.location-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.location-form-container h1 {
  color: var(--color-primary-green);
  margin-bottom: 1.5rem;
}

.form-error-message {
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error-border);
  color: var(--color-error);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.form-tabs {
  display: flex;
  border-bottom: 1px solid var(--color-gray-300);
  margin-bottom: 2rem;
  overflow-x: auto;
}

.form-tab {
  padding: 0.75rem 1.5rem;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  color: var(--color-gray-600);
  cursor: pointer;
  white-space: nowrap;
}

.form-tab:hover {
  color: var(--color-primary-green);
}

.form-tab.active {
  border-bottom-color: var(--color-primary-green);
  color: var(--color-primary-green);
}

.location-form {
  position: relative;
  margin-bottom: 8px;
}

.form-panel {
  display: none;
}

.form-panel.active {
  display: block;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--color-gray-700);
}

.required {
  color: var(--color-error);
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: var(--color-primary-green);
  outline: none;
}

.form-group input.error,
.form-group textarea.error,
.form-group select.error {
  border-color: var(--color-error);
}

.field-error {
  color: var(--color-error);
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid var(--color-gray-300);
}

.form-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

.cancel-button {
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-gray-300);
  color: var(--color-gray-700);
}

.cancel-button:hover {
  background-color: var(--color-gray-200);
}

.submit-button {
  background-color: var(--color-primary-green);
  border: 1px solid var(--color-primary-green);
  color: white;
}

.submit-button:hover {
  background-color: var(--color-secondary-green);
}

.submit-button:disabled {
  background-color: var(--color-gray-400);
  border-color: var(--color-gray-400);
  cursor: not-allowed;
}

.map-reference-preview {
  margin-top: 1rem;
  max-width: 100%;
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
}

.map-reference-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}
