.footer {
  background-color: var(--color-secondary-green);
  color: white;
  padding: 1rem;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftContent,
.rightContent {
  display: flex;
  align-items: center;
}

.copyright,
.rights {
  margin-right: 0.5rem;
}

.logo {
  height: 1rem;
  width: 1rem;
}

.privacyLink {
  color: white;
  text-decoration: none;
}

.privacyLink:hover {
  text-decoration: underline;
}

@media (max-width: 640px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .rightContent {
    margin-top: 0.5rem;
  }
}
