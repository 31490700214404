/* auth-forms.css */
/* Common styles for authentication forms */
.auth-container {
  margin-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.auth-card {
  background: #fff;
  padding: 3rem 2.5rem;
  border-radius: 8px;
  width: 100%;
  min-width: 40vh;
  max-width: 75vh;
  transition: all 0.3s ease;
  /* Book-like shadow */
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.15),
    0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15),
    0 8px 8px rgba(0, 0, 0, 0.15),
    2px 12px 12px rgba(0, 0, 0, 0.15),
    15px 0 20px -10px rgba(0, 0, 0, 0.5);
}

.auth-card:hover {
  transform: translateY(-5px) translateX(-2px);
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 2px rgba(0, 0, 0, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.2),
    0 8px 8px rgba(0, 0, 0, 0.2),
    2px 16px 16px rgba(0, 0, 0, 0.2),
    20px 5px 25px -10px rgba(0, 0, 0, 0.6);
}

.auth-card h2 {
  color: #065f46;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
  font-family: 'Mukta Vaani', Arial, Helvetica;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  position: relative;
}

.auth-card h2:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 10%;
  width: 80%;
  height: 1px;
  background-color: #f0f0e0;
}

.form-group {
  margin-bottom: 1.8rem;
  margin-right: 34px;
  position: relative;
}

.form-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #d4c4b4;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #f0f0e0;
  font-family: 'Mukta Vaani', Arial, Helvetica;
}

.form-input:focus {
  outline: none;
  border-color: #d4c4b4;
  background: #f5e6d3;
  box-shadow: 0 0 0 3px rgba(212, 196, 180, 0.3);
}

.form-input::placeholder {
  /* color: #8b6f5c; */
  font-style: italic;
}

.submit-button {
  width: 100%;
  padding: 0.75rem 1rem;
  background: #fbbf24;
  color: #065f46;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Mukta Vaani', Arial, Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.submit-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.submit-button:hover:not(:disabled) {
  background: #fbbf24;
  transform: translateY(-1px);
}

.submit-button:hover:not(:disabled):before {
  left: 100%;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Error message styling */
.error-message {
  background: #6b300c;
  color: #f5e6d3;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  border: 1px solid #8b4513;
  font-family: 'Mukta Vaani', Arial, Helvetica;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
