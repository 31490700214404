.series-form-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
}

.series-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.series-form-header h1 {
  color: var(--color-primary-green);
  margin: 0;
}

.error-message {
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error-border);
  color: var(--color-error);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.loading {
  text-align: center;
  color: var(--color-gray-600);
  padding: 2rem;
  font-size: 1.2rem;
}

/* Tabs */
.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-gray-300);
  margin-bottom: 1.5rem;
}

.tab {
  padding: 0.75rem 1.25rem;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  font-weight: 500;
  color: var(--color-gray-600);
  transition: all 0.2s;
}

.tab:hover {
  color: var(--color-primary-green);
}

.tab.active {
  color: var(--color-primary-green);
  border-bottom-color: var(--color-primary-green);
}

.tab-panel {
  padding: 1rem 0;
}

/* Form elements */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--color-gray-700);
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  font-size: 1rem;
  transition: border 0.2s;
}

.form-control:focus {
  border-color: var(--color-primary-green);
  outline: none;
}

textarea.form-control {
  resize: vertical;
  min-height: 100px;
}

/* Tags */
.tag-input-container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.tag-input {
  flex-grow: 1;
}

.add-tag-btn {
  background-color: var(--color-primary-green);
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-tag-btn:hover {
  background-color: var(--color-secondary-green);
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-accent-gold);
  color: var(--color-gray-800);
  border-radius: 16px;
  padding: 0.3rem 0.75rem;
  font-size: 0.85rem;
}

.remove-tag {
  background: none;
  border: none;
  color: var(--color-gray-700);
  margin-left: 0.25rem;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.remove-tag:hover {
  color: var(--color-error);
}

/* Image preview */
.image-preview {
  margin-top: 1rem;
  max-width: 300px;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: auto;
  display: block;
}

/* Form progress */
.form-progress {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0 1rem;
}

.progress-step {
  position: relative;
  text-align: center;
  color: var(--color-gray-600);
  flex: 1;
  padding: 0.5rem;
}

.progress-step::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  width: 100%;
  height: 2px;
  background-color: var(--color-gray-300);
  transform: translateY(-50%);
}

.progress-step:last-child::after {
  display: none;
}

.progress-step.active {
  color: var(--color-primary-green);
  font-weight: 500;
}

.progress-step.completed {
  color: var(--color-success);
}

.progress-step.completed::after {
  background-color: var(--color-success);
}

/* Required field indicators */
.required {
  color: var(--color-error);
  margin-left: 0.25rem;
}

.field-hint {
  color: var(--color-gray-500);
  font-size: 0.85rem;
  margin-left: 0.5rem;
  font-weight: normal;
}

/* Form footer */
.form-footer {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--color-gray-200);
}

.form-hint {
  color: var(--color-gray-600);
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

/* Tab completion indicators */
.tab.completed {
  color: var(--color-success);
}

.tab.completed::after {
  content: '✓';
  margin-left: 0.5rem;
  color: var(--color-success);
}

/* Form actions */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--color-gray-200);
}

.cancel-button {
  padding: 0.75rem 1.5rem;
  background-color: var(--color-gray-200);
  color: var(--color-gray-700);
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-button:hover {
  background-color: var(--color-gray-300);
}

.submit-button {
  padding: 0.75rem 1.5rem;
  background-color: var(--color-primary-green);
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: var(--color-secondary-green);
}

.submit-button:disabled {
  background-color: var(--color-gray-400);
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .series-form-container {
    padding: 1rem;
  }

  .series-form-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .tabs {
    flex-wrap: wrap;
  }

  .tab {
    flex: 1 0 auto;
    text-align: center;
    padding: 0.5rem;
  }

  .form-actions {
    flex-direction: column;
  }

  .cancel-button,
  .submit-button {
    width: 100%;
  }
}
