.character-detail-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
}

.character-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--color-gray-200);
}

.header-content {
  flex: 1;
}

.header-content h1 {
  color: var(--color-primary-green);
  margin: 0 0 0.5rem 0;
}

.character-aliases {
  color: var(--color-gray-600);
  font-style: italic;
  margin-bottom: 1rem;
}

.character-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.character-role {
  display: inline-block;
  color: var(--color-gray-600);
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

.character-role[data-role='protagonist'] {
  background-color: var(--color-primary-green);
  color: var(--color-white);
}

.character-role[data-role='antagonist'] {
  background-color: var(--color-error);
  color: var(--color-white);
}

.character-role[data-role='supporting'] {
  background-color: var(--color-accent-gold);
  color: var(--color-gray-800);
}

.character-role[data-role='minor'] {
  background-color: var(--color-gray-400);
  color: var(--color-white);
}

.character-occupation {
  display: inline-block;
  padding: 0.3rem 0.75rem;
  border-radius: 16px;
  background-color: var(--color-gray-200);
  color: var(--color-gray-700);
  font-size: 0.85rem;
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.character-detail-tabs {
  display: flex;
  border-bottom: 1px solid var(--color-gray-300);
  margin-bottom: 2rem;
  overflow-x: auto;
}

.detail-tab {
  padding: 0.75rem 1.5rem;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  color: var(--color-gray-600);
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s;
}

.detail-tab:hover {
  color: var(--color-primary-green);
}

.detail-tab.active {
  border-bottom-color: var(--color-primary-green);
  color: var(--color-primary-green);
}

.character-detail-content {
  position: relative;
}

.detail-panel {
  display: none;
}

.detail-panel.active {
  display: block;
}

.detail-section {
  margin-bottom: 2.5rem;
}

.detail-section h2 {
  color: var(--color-secondary-green);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.detail-section p {
  line-height: 1.6;
  color: var(--color-gray-800);
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
}

.info-item {
  background-color: var(--color-gray-50);
  padding: 1rem;
  border-radius: 6px;
  border-left: 4px solid var(--color-accent-gold);
}

.info-item h3 {
  color: var(--color-gray-700);
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
}

.info-item p {
  margin: 0;
  color: var(--color-gray-900);
  font-weight: 500;
}

.character-goals,
.character-fears {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.character-goals li,
.character-fears li {
  padding: 0.75rem 1rem;
  background-color: var(--color-gray-50);
  margin-bottom: 0.5rem;
  border-radius: 4px;
  border-left: 3px solid var(--color-primary-green);
}

.character-fears li {
  border-left-color: var(--color-error);
}

.relationships-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
}

.relationship-item {
  background-color: var(--color-gray-50);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.relationship-item h3 {
  color: var(--color-primary-green);
  margin-top: 0;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid var(--color-gray-200);
  padding-bottom: 0.5rem;
}

.relationship-item a {
  display: inline-block;
  margin-top: 1rem;
  color: var(--color-primary-green);
  text-decoration: none;
}

.relationship-item a:hover {
  text-decoration: underline;
}

.empty-relationships {
  text-align: center;
  padding: 3rem;
  background-color: var(--color-gray-50);
  border-radius: 8px;
}

.add-relationship-btn {
  background-color: var(--color-gray-200);
  border: none;
  border-radius: 4px;
  padding: 0.6rem 1.2rem;
  margin-top: 1rem;
  cursor: pointer;
}

.add-relationship-btn:hover {
  background-color: var(--color-gray-300);
}

.error-container {
  text-align: center;
  padding: 3rem;
}

.error-message {
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error-border);
  color: var(--color-error);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.back-link {
  display: inline-block;
  color: var(--color-primary-green);
  text-decoration: none;
}

.back-link:hover {
  text-decoration: underline;
}

.loading-indicator {
  text-align: center;
  color: var(--color-gray-600);
  padding: 2rem;
  font-size: 1.2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .character-detail-container {
    padding: 1rem;
  }

  .character-detail-header {
    flex-direction: column;
  }

  .header-actions {
    margin-top: 1rem;
    width: 100%;
  }

  .character-detail-tabs {
    margin-bottom: 1.5rem;
  }
}
