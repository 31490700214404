.container {
  padding: 1.5rem;
  max-width: 100%;
  margin: 0 auto;
}

.title {
  color: var(--color-primary-green);
  margin-bottom: 0.5rem;
  font-size: 2rem;
}

.description {
  color: var(--color-gray-600);
  margin-bottom: 2rem;
}

.statsSection,
.jobsSection {
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
}

.jobsSection {
  background-color: var(--color-gray-50);
}

.sectionTitle {
  color: var(--color-secondary-green);
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.timeframeSelector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
  background-color: var(--color-white);
  color: var(--color-gray-800);
  font-size: 0.9rem;
}

.select:focus {
  outline: none;
  border-color: var(--color-primary-green);
  box-shadow: 0 0 0 2px rgba(44, 95, 45, 0.1);
}

.jobsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  width: 100%;
}

.jobList,
.jobDetails {
  background-color: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.noJobSelected {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: var(--color-gray-500);
  font-style: italic;
  background-color: var(--color-gray-50);
  border-radius: 4px;
  border: 2px dashed var(--color-gray-200);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .jobsContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .statsSection,
  .jobsSection {
    padding: 1rem;
  }

  .title {
    font-size: 1.5rem;
  }
}
