/* // src/components/AdminDashboardLayout.module.css */
.adminDashboard {
  display: flex;
  height: 100vh;
  width: 100%;
}

.sidebar {
  width: 250px;
  background-color: var(--color-gray-100);
  padding: 20px;
  border-right: 1px solid var(--color-gray-200);
  overflow-y: auto;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  color: var(--color-gray-700);
  text-decoration: none;
  display: block;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.sidebar a:hover {
  background-color: var(--color-gray-200);
}

.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
}
