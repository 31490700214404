.royaltiesSummary {
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  min-height: calc(100vh - 120px);
}

.royaltiesSummary h1 {
  margin-bottom: 40px;
  color: #333;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

.royaltiesGrid {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 30px 0;
}

.royaltyCard {
  padding: 20px;
}

/* Row layout inside each card */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.header h2 {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
  font-weight: normal;
  /* Prevent long titles from breaking layout */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}

.cardContent {
  padding: 8px 0;
  border-top: 1px solid #eee;
}

.section {
  margin-top: 16px;
  padding-bottom: 16px;
}

.section h4 {
  margin: 16px 0 8px 0;
  font-size: 0.95rem;
  color: #555;
  font-weight: 500;
}

/* Ranges styling */
.ranges {
  margin-bottom: 16px;
}

.ranges ul {
  margin-top: 8px;
  padding-left: 20px;
}

.ranges li {
  margin-bottom: 4px;
}

/* Distributions styling - new flex layout */
.distributions {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 8px;
}

.distribution {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 10px 14px;
  min-width: 140px;
}

.distribution span:first-child {
  font-weight: 500;
  margin-bottom: 4px;
  color: #333;
}

.distribution span:last-child {
  font-size: 0.85rem;
  color: #666;
}

/* Container for the Setup New Book button */
.buttonContainer {
  margin-top: 36px;
  display: flex;
  justify-content: center;
}

/* Loading and error states - fixed selector */
.royaltiesSummary > .loading {
  font-size: 1rem;
  color: #666;
  margin: 40px 0;
  text-align: center;
}

/* Error styling */
.error {
  color: #d32f2f;
  padding: 16px;
  background-color: #ffebee;
  border-radius: 6px;
  margin: 20px 0;
  text-align: center;
}

/* Media queries for better responsiveness */
@media (max-width: 768px) {
  .royaltiesSummary {
    padding: 30px 20px;
  }

  .header h2 {
    max-width: 300px;
    font-size: 1rem;
  }

  .distributions {
    gap: 8px;
  }

  .distribution {
    min-width: 120px;
    padding: 8px 12px;
  }
}

@media (max-width: 480px) {
  .royaltiesSummary {
    padding: 20px 16px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .header h2 {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .royaltiesSummary h1 {
    font-size: 1.6rem;
    margin-bottom: 30px;
  }

  .distributions {
    flex-direction: column;
    gap: 8px;
  }

  .distribution {
    width: 100%;
  }
}
