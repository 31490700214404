/* Submissions.module.css */
.container {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}

.titleContainer {
  display: inline-flex;
  align-items: baseline;
  margin-bottom: 1rem;
}

.workingContainer {
  min-height: 540px;
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.evaluationButton {
  padding: 0.5rem 1rem;
  background-color: #2563eb;
  color: white;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.evaluationButton:hover {
  background-color: #1d4ed8;
}
