.button {
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Variants */
.primary {
  background-color: var(--primary-color, #007aff);
  color: white;
}

.primary:hover:not(:disabled) {
  background-color: var(--primary-color-dark, #0056b3);
}

.secondary {
  background-color: var(--secondary-color, #f5f5f5);
  color: var(--text-color, #333);
  border: 1px solid var(--border-color, #e0e0e0);
}

.secondary:hover:not(:disabled) {
  background-color: var(--secondary-color-hover, #e8e8e8);
}

.danger {
  background-color: var(--danger-color, #ff3b30);
  color: white;
}

.danger:hover:not(:disabled) {
  background-color: var(--danger-color-dark, #d63030);
}

/* Sizes */
.small {
  padding: 6px 12px;
  font-size: 14px;
}

.medium {
  padding: 8px 16px;
  font-size: 16px;
}

.large {
  padding: 12px 24px;
  font-size: 18px;
}

.fullWidth {
  width: 100%;
}
