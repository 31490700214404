.container {
  width: 100%;
}

.header {
  margin-bottom: 1rem;
}

.title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.legend {
  margin-bottom: 1rem;
}

.legendSection {
  margin-bottom: 0.5rem;
}

.legendTitle {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.legendItems {
  display: flex;
  gap: 1rem;
}

.legendItem {
  display: flex;
  align-items: center;
}

.legendText {
  font-size: 0.875rem;
}

.characterDot {
  color: #4299e1;
}

.externalDot {
  color: #ed8936;
}

.chartContainer {
  width: 100%;
  height: 24rem;
}

.tooltip {
  background-color: white;
  padding: 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
  max-width: 28rem;
}

.tooltipTitle {
  font-weight: bold;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

.tooltipDescription {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.tooltipDetail {
  font-size: 0.875rem;
  color: #4a5568;
  margin-bottom: 0.25rem;
}

.connectionsContainer {
  margin-top: 1rem;
}

.connectionsTitle {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.connectionsList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.connectionItem {
  padding: 0.5rem;
  background-color: #f7fafc;
  border-radius: 0.25rem;
}

.connectionHeader {
  font-size: 0.875rem;
}

.connectionBeats {
  font-weight: 500;
}

.connectionDivider {
  margin: 0 0.5rem;
  color: #718096;
}

.connectionType {
  color: #4a5568;
}

.connectionStrength {
  color: #4a5568;
}

.connectionExplanation {
  font-size: 0.875rem;
  color: #4a5568;
  margin-top: 0.25rem;
}
