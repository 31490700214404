.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
}

.select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 0.875rem;
}

.select:focus {
  outline: none;
  border-color: #4caf50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.chartContainer {
  height: 300px;
  width: 100%;
}

.tableContainer {
  margin-top: 1rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}

.tableHeader {
  text-align: left;
  padding: 0.5rem;
  background-color: #f5f5f5;
  font-weight: 600;
}

.tableRow {
  border-top: 1px solid #eee;
}

.tableCell {
  padding: 0.5rem;
}

.rightAlign {
  text-align: right;
}

.analysisContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.label {
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .chartContainer {
    height: 400px; /* Taller on mobile for better readability */
  }

  .controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .select {
    width: 100%;
  }
}
