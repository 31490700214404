.fileList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.fileItem {
  padding: 1rem 0;
  border-bottom: 1px solid var(--color-gray-300);
}

.fileItem:last-child {
  border-bottom: none;
}

.fileLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  transition: background-color 0.2s ease;
  color: var(--color-link);
}

.scoreAndDate {
  display: flex;
  justify-content: space-between;
}

.fileLink:hover {
  background-color: var(--color-gray-50);
}

.fileName {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--color-link);
}

.fileDate {
  font-size: 0.875rem;
  color: var(--color-gray-600);
  margin-left: 8px;
}

.fileScore {
  font-size: 0.875rem;
  color: var(--color-gray-600);
}

.message {
  padding: 1rem;
  text-align: center;
  color: var(--color-gray-700);
}

.fileVersion {
  font-size: 0.875rem;
  color: var(--color-gray-600);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 470px;
}

.listContainer {
  flex-grow: 1;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
  margin-top: auto; /* Additional insurance for bottom alignment */
}

.paginationButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 0.375rem;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.paginationButton:hover:not(:disabled) {
  background-color: var(--color-gray-50);
  border-color: var(--color-gray-400);
}

.paginationButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.paginationIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--color-gray-700);
}

.pageInfo {
  font-size: 0.875rem;
  color: var(--color-gray-600);
}
