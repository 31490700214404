.sectionDescription {
  margin-bottom: 1.5rem;
  color: #4b5563;
  line-height: 1.5;
}

.pendingAnalysis {
  padding: 1rem;
  color: #6b7280;
  text-align: center;
  font-style: italic;
}

.analysisContainer {
  margin-top: 1rem;
}

.analysisContent {
  white-space: pre-wrap;
  font-family: monospace;
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.noAnalysis {
  text-align: center;
  padding: 2rem;
  color: #6b7280;
}

.analysisButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.analysisButton:hover {
  background-color: #1d4ed8;
}

.analysisButton:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.chapterNavigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.navButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.navButton:hover:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

.navButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.metricsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.metricCard {
  padding: 1.5rem;
  text-align: center;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.95)
  );
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.metricCard h4 {
  margin: 0 0 0.75rem 0;
  color: #4b5563;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.metricCard p {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 600;
  color: #1f2937;
  line-height: 1.2;
}

.chartsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.chartCard {
  padding: 1.5rem;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.95)
  );
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.chartCard h4 {
  margin: 0 0 1.25rem 0;
  color: #4b5563;
  text-align: center;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.listsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.listCard {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.95)
  );
  border: 1px solid rgba(0, 0, 0, 0.05);
  max-width: 100%;
  overflow-x: hidden;
}

.listCard h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: center;
  color: #1f2937;
}

.listCard ul {
  margin: 0;
  padding-left: 1.5rem;
  list-style-type: disc;
  color: #4b5563;
}

.listCard li {
  margin-bottom: 0.5rem;
  line-height: 1.4;
  font-size: 0.9375rem;
}

.chartContainer {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  max-width: 100%;
  overflow-x: hidden;
}

.subtitle {
  color: #6b7280;
  font-size: 0.9em;
  font-weight: normal;
}

.pendingAnalysis {
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.noAnalysis {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}

.actionDensityGuide {
  padding: 1rem;
}

.actionDensityGuide section {
  margin-bottom: 2rem;
}

.actionDensityGuide h5 {
  font-size: 1.1rem;
  margin: 0 0 0.5rem 0;
  font-weight: 600;
}

.densityRange {
  font-size: 0.9rem;
  color: #666;
  margin: 0 0 1rem 0;
  font-style: italic;
}

.actionDensityGuide ul {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
}

.actionDensityGuide li {
  margin-bottom: 0.75rem;
  line-height: 1.5;
}

.actionDensityGuide .note {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
}
