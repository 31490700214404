.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.title {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  text-align: center;
}

.subtitle {
  font-size: 1.8rem;
  color: #3498db;
  margin-bottom: 1.5rem;
  text-align: center;
}

.section {
  margin-bottom: 2rem;
}

.sectionTitle {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.list {
  padding-left: 1.5rem;
}

.list li {
  margin-bottom: 1rem;
}

.ctaContainer {
  text-align: center;
  margin-top: 2rem;
}

.cta {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta:hover {
  background-color: #2980b9;
}

.footer {
  margin-top: 2rem;
  text-align: center;
  font-style: italic;
  color: #7f8c8d;
  font-family: 'Mukta Vaani', Arial, Helvetica;
}

@media (max-width: 600px) {
  .container {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .sectionTitle {
    font-size: 1.3rem;
  }
}
