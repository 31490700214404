.container {
  position: relative;
  display: inline-block;
}

.trigger {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #fff;
  font: inherit;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.trigger:hover {
  color: #d4af37;
}

.chevron {
  transition: transform 0.2s ease;
  stroke: currentColor;
}

.chevron.open {
  transform: rotate(180deg);
}

.dropdown {
  position: absolute;
  top: calc(100% + 1rem);
  left: 50%;
  transform: translateX(-50%);
  min-width: 280px;
  background: #2c5f2d;
  border: 2px solid #ffd700;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  padding: 0.5rem;
}

.item {
  display: block;
  padding: 0.75rem;
  text-decoration: none;
  color: #fff;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.item:hover {
  background-color: rgba(255, 215, 0, 0.1);
  color: #d4af37;
}

.productName {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.productDescription {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }

  .trigger {
    width: 100%;
    justify-content: center;
    padding: 0.5rem 0;
  }

  .dropdown {
    position: static;
    width: 100%;
    transform: none;
    margin-top: 0.5rem;
    border-left: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none;
    background-color: rgba(44, 95, 45, 0.95);
  }

  .item {
    text-align: center;
  }

  .productDescription {
    margin: 0 auto;
    max-width: 90%;
  }
}

.section {
  padding: 0.5rem;
}

.section + .section {
  border-top: 1px solid #eee;
  margin-top: 0.5rem;
  padding-top: 1rem;
}

.sectionTitle {
  font-weight: 600;
  color: #d4af37;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
}
