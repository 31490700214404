.container {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  padding: 8px 32px 8px 12px;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5;
  background-color: white;
  transition: border-color 0.2s ease;
  margin: 0;
  box-sizing: border-box;
}

.input:focus {
  outline: none;
  border-color: var(--color-primary-green);
  box-shadow: 0 0 0 2px rgba(44, 95, 45, 0.1);
}

.disabled .input {
  background-color: white;
  cursor: not-allowed;
  opacity: 1;
}

.toggleButton {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 4px;
  color: var(--color-gray-600);
  cursor: pointer;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleButton:focus {
  outline: none;
}

.disabled .toggleButton {
  cursor: not-allowed;
  color: var(--color-gray-400);
}

.optionsList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 4px 0 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.option {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.option:hover,
.highlighted {
  background-color: var(--color-gray-100);
}

.selected {
  background-color: rgba(44, 95, 45, 0.1);
  font-weight: 500;
}

.noResults {
  padding: 8px 12px;
  color: var(--color-gray-500);
  font-style: italic;
}
