.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  margin-bottom: -1px; /* Pull tabs down to overlap with content */
  position: relative;
  z-index: 10;
  justify-content: space-between;
}

.tabButton {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #e5e7eb;
  color: #4b5563;
  border: 1px solid #d1d5db;
  border-bottom: none;
  border-radius: 0.5rem 0.5rem 0 0;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  margin-bottom: 0;
}
.tabButton:not(.settings) {
  margin-right: 0.25rem;
}
.settings {
  margin-left: auto;
}

.tabButton:hover {
  background-color: #f3f4f6;
}

.tabButton.active {
  background-color: #ffffff;
  color: #065f46;
  border-color: #d1d5db;
  border-bottom: 1px solid #ffffff;
  margin-bottom: -1px;
  z-index: 20;
}

.tabButton.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ffffff;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  opacity: 0.8;
}

.tabButton.active .icon {
  opacity: 1;
}

.tabLabel {
  display: none;
  font-weight: 500;
}

@media (min-width: 768px) {
  .tabLabel {
    display: inline;
  }
}

.tabContent {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 5;
}

/* Add a subtle inner shadow to the content area */
.tabContent::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.02) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 0.5rem 0.5rem 0 0;
}

.sectionNav {
  position: sticky;
  top: 0;
  background: white;
  padding: 1rem 0;
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid #eee;
  z-index: 10;
  margin-bottom: 2rem;
}

.sectionLink {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  color: #666;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.2s;
}

.sectionLink:hover {
  color: #000;
}

.section {
  scroll-margin-top: 5rem;
}

.deleteSection {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}

.deleteWarning {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.deleteButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.deleteButton:hover {
  background-color: #c82333;
}

.deleteButton:disabled {
  background-color: #e9a8ae;
  cursor: not-allowed;
}
