/* src/components/SupportBanner/SupportBanner.module.css */
.supportBanner {
  background-color: #ffd700; /* Golden background to stand out */
  color: #2c5f2d; /* Deep green text to match header background */
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Mukta Vaani', Arial, Helvetica;
  font-size: 0.9rem;
  font-weight: 500;
  position: relative;
  z-index: 1001; /* Ensure it's above other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.supportBanner p {
  margin: 0;
}

.supportBanner button {
  background-color: #2c5f2d; /* Deep green to match header */
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: inherit;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.supportBanner button:hover {
  background-color: #1e4721; /* Slightly darker green on hover */
}

@media (max-width: 768px) {
  .supportBanner {
    flex-direction: column;
    padding: 0.5rem 1rem;
    text-align: center;
  }

  .supportBanner p {
    margin-bottom: 0.5rem;
  }

  .supportBanner button {
    width: 100%;
  }
}
