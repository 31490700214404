/* InfoTooltip.module.css */
.container {
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-left: 0.1em;
  transform: translateY(-0.5em);
  line-height: 0;
}

.button {
  display: inline-flex;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  cursor: help;
  color: inherit;
  font-family: serif;
  line-height: 0;
}

.reference {
  font-size: 0.7em;
  color: #6b7280;
  transition: color 0.2s ease;
}

.button:hover .reference {
  color: #374151;
}

.button:focus {
  outline: none;
}

.button:focus .reference {
  color: #374151;
}

.tooltip {
  position: absolute;
  z-index: 50;
  padding: 0.5rem 0.75rem;
  width: 280px;
  max-width: min(280px, 90vw);
  background-color: #111827;
  color: white;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  white-space: normal;
  font-family: sans-serif;
  line-height: 1.5;
  transition: all 0.2s ease;
}

.tooltipSymbol {
  font-family: serif;
  opacity: 0.7;
  margin-right: 0.25em;
}

/* Rest of the CSS remains the same */
.arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #111827;
  transform: rotate(45deg);
}

/* Position variants */
.top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
}

.right {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0.5rem;
}

.bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.5rem;
}

.left {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 0.5rem;
}

/* Arrow positioning */
.arrow-top {
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.arrow-right {
  left: -4px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.arrow-bottom {
  top: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.arrow-left {
  right: -4px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
