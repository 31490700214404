.character-management-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.character-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.character-management-header h1 {
  color: var(--color-primary-green);
  margin: 0;
}

.create-character-btn {
  background-color: var(--color-primary-green);
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-character-btn:hover {
  background-color: var(--color-secondary-green);
}

.character-management-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.search-filter-container {
  display: flex;
  gap: 1rem;
  flex: 1;
}

.search-container {
  flex: 1;
}

.character-search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  font-size: 1rem;
}

.character-search-input:focus {
  border-color: var(--color-primary-green);
  outline: none;
}

.filter-container {
  width: 180px;
}

.character-filter-select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--color-white);
}

.character-filter-select:focus {
  border-color: var(--color-primary-green);
  outline: none;
}

.view-mode-container {
  display: flex;
  gap: 0.5rem;
}

.view-mode-btn {
  background-color: var(--color-gray-200);
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.view-mode-btn.active {
  background-color: var(--color-accent-gold);
  color: var(--color-gray-800);
  font-weight: 600;
}

.loading-indicator {
  text-align: center;
  color: var(--color-gray-600);
  padding: 2rem;
  font-size: 1.2rem;
}

.error-message {
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error-border);
  color: var(--color-error);
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
  margin: 2rem 0;
}

.empty-state {
  text-align: center;
  padding: 3rem;
  background-color: var(--color-gray-50);
  border-radius: 8px;
}

.empty-state h2 {
  color: var(--color-primary-green);
  margin-bottom: 1rem;
}

.empty-state p {
  color: var(--color-gray-600);
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.character-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
}

.character-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.character-card {
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  position: relative;
  cursor: pointer;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  overflow: hidden;
}

.character-grid .character-card {
  height: 280px;
}

.character-list .character-card {
  display: flex;
  align-items: center;
}

.character-list .character-card h3 {
  flex: 0 0 200px;
}

.character-list .character-details {
  flex: 1;
  display: flex;
  gap: 2rem;
}

.character-list .character-description {
  flex: 1;
  max-width: 400px;
}

.character-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.character-card h3 {
  color: var(--color-primary-green);
  margin: 0 0 0.5rem 0;
  padding-left: 1rem;
}

.character-role-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
}

.character-role-indicator[data-role='protagonist'] {
  background-color: var(--color-primary-green);
}

.character-role-indicator[data-role='antagonist'] {
  background-color: var(--color-error);
}

.character-role-indicator[data-role='supporting'] {
  background-color: var(--color-accent-gold);
}

.character-role-indicator[data-role='minor'] {
  background-color: var(--color-gray-400);
}

.character-aliases {
  color: var(--color-gray-600);
  font-size: 0.9rem;
  margin-bottom: 1rem;
  font-style: italic;
}

.character-details {
  margin-bottom: 1rem;
}

.detail-label {
  color: var(--color-gray-700);
  font-weight: 600;
}

.character-description {
  color: var(--color-gray-700);
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3; /* standard property for compatibility */
  -webkit-box-orient: vertical;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .character-management-header,
  .character-management-controls {
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
  }

  .search-filter-container {
    flex-direction: column;
  }

  .filter-container {
    width: 100%;
  }

  .character-list .character-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .character-list .character-card h3,
  .character-list .character-details,
  .character-list .character-description {
    flex: 1 1 auto;
    width: 100%;
    max-width: 100%;
  }
}
