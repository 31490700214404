.container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  height: calc(100vh - 60px); /* Adjust based on your header height */
  overflow: auto;
}

.title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #333;
}

.promptGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1.5rem;
}

.promptCard {
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.promptHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.promptName {
  font-size: 1.25rem;
  margin: 0;
  color: #333;
  font-weight: 600;
}

.buttonGroup {
  display: flex;
  gap: 0.5rem;
}

.editButton,
.deleteButton {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.editButton {
  background-color: #e0e0e0;
  color: #333;
}

.editButton:hover:not(:disabled) {
  background-color: #d0d0d0;
}

.deleteButton {
  background-color: #ff4444;
  color: white;
}

.deleteButton:hover:not(:disabled) {
  background-color: #ff2222;
}

.editButton:disabled,
.deleteButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.promptString {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
  font-family: monospace;
  font-size: 0.875rem;
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}
