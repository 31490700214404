/* src/components/FileUploader/FileUploader.module.css */
.uploaderContainer {
  background-color: #f0f4f8;
  border: 2px dashed #a0aec0;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  transition: all 0.3s ease;
}

.uploaderContainer:hover {
  border-color: #4a5568;
}

.instructions {
  margin-bottom: 15px;
  color: #4a5568;
  font-size: 0.9rem;
  text-align: center;
}

.fileInput {
  display: none;
}

.fileInputLabel {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2c5f2d;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.fileInputLabel:hover {
  background-color: #234c24;
}

.selectedFileName {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #4a5568;
}

.inputGroup {
  margin-top: 15px;
}

.inputLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #2d3748;
}

.titleInput,
.selectExisting {
  width: 100%;
  padding: 8px;
  border: 1px solid #a0aec0;
  border-radius: 4px;
  font-size: 0.9rem;
}

.inputDescription {
  margin-top: 5px;
  font-size: 0.8rem;
  color: #718096;
}

.uploadButton {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #d4af37;
  color: #2c5f2d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.uploadButton:hover:not(:disabled) {
  background-color: #c49f31;
}

.uploadButton:disabled {
  background-color: #e2e8f0;
  color: #a0aec0;
  cursor: not-allowed;
}

.errorBox {
  margin-top: 8px;
}

.uploadSpacing {
  margin-top: 8px;
}
