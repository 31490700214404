.royaltyTable {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.tableHeader {
  padding: 12px 8px;
  border-bottom: 2px solid #ccc;
  text-align: left;
  border-left: none;
  border-right: none;
}

.tableCell {
  padding: 12px 8px;
  border-bottom: 1px solid #ddd;
  /* Remove vertical borders */
  border-left: none;
  border-right: none;
}

.tableRow:nth-child(odd) {
  background: linear-gradient(
    to right,
    transparent,
    #f0f0f0 20px,
    #f0f0f0 calc(100% - 20px),
    transparent
  );
}

.splitInputsContainer {
  display: flex;
  gap: 10px;
}

.splitInputWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.splitButton {
  margin-left: 24px;
}

.splitLabel {
  font-size: 0.85rem;
  margin-bottom: 4px;
  color: #111;
}

.splitInput {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
