.evaluationContainer {
  margin-bottom: 1rem;
}

.evaluationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.evaluationItem {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.confidenceWrapper {
  font-size: 0.875rem;
}

.confidenceHigh {
  color: #059669;
}

.confidenceMedium {
  color: #d97706;
}

.confidenceLow {
  color: #dc2626;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.downloadLinks {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.downloadLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f3f4f6;
  border-radius: 0.375rem;
  color: #374151;
  text-decoration: none;
  transition: background-color 0.2s;
}

.downloadLink:hover {
  background-color: #e5e7eb;
}
