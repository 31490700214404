/* src/components/SubscriptionPlans/PricingTable.module.css */
.pricingContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.pricingCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #e4e4e7;
  border-radius: 8px;
  height: 100%;
  transition: all 0.2s ease;
}

.pricingCard:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

/* Highlight the Pro plan */
.pricingCard:nth-child(2) {
  border: 2px solid #2563eb;
}

.pricingCard:nth-child(2)::before {
  content: 'Most Popular';
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: #2563eb;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.25rem 1rem;
  border-radius: 9999px;
}

.cardHeader {
  padding: 1.5rem;
  text-align: center;
}

.planName {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
}

.planDescription {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0 0 1.5rem 0;
}

.priceContainer {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin: 1rem 0;
}

.currency {
  font-size: 1.5rem;
  font-weight: 600;
  color: #4b5563;
}

.price {
  font-size: 3rem;
  font-weight: 700;
  margin: 0 0.25rem;
}

.period {
  color: #6b7280;
}

.cardContent {
  flex-grow: 1;
  padding: 0 1.5rem;
}

.featureList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.featureItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.checkIcon {
  color: #22c55e;
  flex-shrink: 0;
}

.cardFooter {
  padding: 1.5rem;
}

.subscribeButton {
  width: 100%;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.subscribeButton:not(:disabled) {
  background: #2563eb;
  color: white;
}

.subscribeButton:not(:disabled):hover {
  background: #1d4ed8;
}

.subscribeButton:disabled {
  background: #e5e7eb;
  color: #9ca3af;
  cursor: not-allowed;
}

/* Enterprise plan button styling */
.pricingCard:last-child .subscribeButton {
  background: #18181b;
}

.pricingCard:last-child .subscribeButton:not(:disabled):hover {
  background: #27272a;
}

/* Media Queries */
@media (max-width: 768px) {
  .pricingContainer {
    grid-template-columns: 1fr;
    max-width: 400px;
  }
}
