/* EmotionalAnalysis.module.css */
.container {
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.insightRow {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.emotionBadgeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.emotionBadge {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  border-radius: 9999px;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
}

.toggleContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.toggleButton {
  padding: 0.375rem 0.75rem;
  border-radius: 9999px;
  border: 1px solid #e2e8f0;
  font-size: 0.875rem;
  transition: all 0.2s;
  cursor: pointer;
  background-color: transparent;
}

.toggleButton:hover {
  opacity: 0.9;
}

.toggleButtonSelected {
  border-color: transparent;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.chartContainer {
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

.sectionTitle {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.transitionsList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.transitionItem {
  display: flex;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f9fafb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.magnitudeContainer {
  margin-right: 1rem;
  flex-shrink: 0;
  text-align: center;
}

.magnitudeValue {
  display: block;
  font-size: 1.25rem;
  font-weight: 700;
}

.magnitudeLabel {
  font-size: 0.75rem;
  color: #6b7280;
}

.transitionContent {
  flex: 1;
}

.transitionText {
  font-size: 0.875rem;
  color: #4b5563;
  font-style: italic;
  margin: 0.25rem 0;
}

.chapterLocation {
  font-size: 0.75rem;
  color: #6b7280;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
}

.transitionArrow {
  display: flex;
  align-items: center;
  margin: 0.25rem 0;
}

.emotionTagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.emotionTag {
  padding: 0.125rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  color: white;
}

.infoGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .infoGrid {
    grid-template-columns: 1fr 1fr;
  }
}

.infoCard {
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 0.5rem;
}

.insightCard {
  padding: 0.75rem;
  border-left-width: 4px;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.insightCardBlue {
  border-left-color: #3b82f6;
  background-color: #eff6ff;
}

.insightCardPurple {
  border-left-color: #8b5cf6;
  background-color: #f5f3ff;
}

.insightCardGreen {
  border-left-color: #10b981;
  background-color: #ecfdf5;
}

.insightTitle {
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.emotionIndicator {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 9999px;
  margin-right: 0.5rem;
}

.statRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-top: 0.25rem;
}

.statItem {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.insightsList {
  font-size: 0.875rem;
  margin-top: 0.25rem;
  padding-left: 0;
  list-style-type: none;
}

.insightItem {
  margin-bottom: 0.25rem;
}

.emptyState {
  color: #6b7280;
  font-style: italic;
}

.tooltipContainer {
  padding: 0.75rem;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  border: 1px solid #e2e8f0;
  max-width: 300px;
}

.tooltipTitle {
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.25rem;
}

.tooltipChapter {
  font-size: 0.75rem;
  color: #6b7280;
  margin: 0 0 0.25rem 0;
  font-weight: 600;
}

.tooltipText {
  font-size: 0.875rem;
  color: #4b5563;
  font-style: italic;
  margin-bottom: 0.5rem;
}

.tooltipStats {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.tooltipRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.tooltipEmotionName {
  display: flex;
  align-items: center;
}

.tooltipEmotionIndicator {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 9999px;
  margin-right: 0.5rem;
}

.tooltipValue {
  font-size: 0.875rem;
  margin: 0;
}

.emotionalTransitionsContainer {
  margin-top: 2rem;
}
