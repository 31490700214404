.section {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.title {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
}

.icon {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.iconOpen {
  transform: rotate(180deg);
}

.content {
  padding: 1rem 0;
  width: 100%;
  overflow-wrap: break-word;
}
