.carouselContainer {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.carouselImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
}

.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navButton:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

.navButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px white;
}

.prevButton {
  left: 16px;
}

.nextButton {
  right: 16px;
}

.indicators {
  position: absolute;
  bottom: 64px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.indicatorActive {
  background-color: white;
  transform: scale(1.25);
}

/* Modal styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalImageContainer {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.modalImage {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.modalCaption {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
}

.closeButton {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px;
  transition: opacity 0.3s ease;
}

.closeButton:hover {
  opacity: 0.8;
}

.closeButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px white;
}

.modalNavigation {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  pointer-events: none; /* Allow clicks to pass through to modal overlay */
}

.modalNavButton {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 16px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  pointer-events: auto; /* Re-enable clicks on buttons */
}

.modalNavButton:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

.modalNavButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px white;
}

.modalPrevButton {
  margin-left: 16px;
}

.modalNextButton {
  margin-right: 16px;
}

/* Accessibility-focused styles */
@media (prefers-reduced-motion: reduce) {
  .modalOverlay {
    transition: none;
  }
}

/* Support for high contrast mode */
@media (forced-colors: active) {
  .closeButton,
  .modalNavButton {
    border: 1px solid currentColor;
  }
}
