@import url('https://fonts.googleapis.com/css2?family=Mukta+Vaani:wght@200..900&display=swap');

/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Mukta Vaani', Arial, Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0e0;
}

/* Improve form element defaults */
input,
button {
  font-family: 'Mukta Vaani', Arial, Helvetica;
}
