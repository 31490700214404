/* // src/pages/UserManagement.module.css */
.userTable {
  width: 100%;
  border-collapse: collapse;
}

.userTable th,
.userTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.userTable th {
  background-color: #f2f2f2;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  margin: 0 10px;
}
