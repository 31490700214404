/* src/pages/BugTracking.module.css */
.bugTrackingContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Adjust based on your header height */
  overflow: hidden;
}

.bugSummary {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bugSummary h2 {
  margin-top: 0;
  color: #333;
}

.bugSummary p {
  font-size: 18px;
  margin-bottom: 0;
}

.bugSummary strong {
  color: #dc3545;
  font-size: 24px;
}

.bugListContainer {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.bugList {
  overflow-y: auto;
  padding-right: 15px; /* Add some padding for the scrollbar */
}

.bugItem {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
}

.bugItem h3 {
  margin-top: 0;
}

.bugItem pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
}
