.container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
}

.header {
  padding: 1rem;
  border-bottom: 1px solid var(--color-gray-200);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white);
}

.header h3 {
  margin: 0;
  color: var(--color-gray-700);
  font-size: 1.1rem;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  background-color: var(--color-white);
}

.section {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--color-gray-200);
}

.section:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.section h4 {
  color: var(--color-secondary-green);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.infoItem {
  padding: 0.75rem;
  background-color: var(--color-gray-50);
  border-radius: 4px;
  border: 1px solid var(--color-gray-200);
}

.infoLabel {
  font-size: 0.9rem;
  color: var(--color-gray-600);
  margin-bottom: 0.25rem;
}

.infoValue {
  font-size: 1rem;
  color: var(--color-gray-800);
  font-weight: 500;
  word-break: break-word;
}

.codeBlock {
  background-color: var(--color-gray-50);
  border: 1px solid var(--color-gray-200);
  border-radius: 4px;
  padding: 1rem;
  font-family: monospace;
  font-size: 0.9rem;
  color: var(--color-gray-800);
  max-height: 300px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

.errorDetails {
  background-color: var(--color-gray-50);
  border: 1px solid var(--color-error);
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;
}

.errorMessage {
  background-color: rgba(220, 38, 38, 0.05);
  border: 1px solid var(--color-error);
  border-radius: 4px;
  padding: 1rem;
}

.errorTitle {
  color: var(--color-error);
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.errorStack {
  background-color: var(--color-gray-50);
  padding: 0.75rem;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.85rem;
  color: var(--color-gray-800);
  overflow-x: auto;
  max-height: 200px;
  overflow-y: auto;
}

.actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.actionButton {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.actionButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.retryButton {
  background-color: var(--color-primary-green);
  color: var(--color-white);
}

.retryButton:hover:not(:disabled) {
  background-color: var(--color-secondary-green);
}

.cancelButton {
  background-color: var(--color-error);
  color: var(--color-white);
}

.cancelButton:hover:not(:disabled) {
  background-color: var(--color-error);
  opacity: 0.9;
}

.statusBadge {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.statusCompleted {
  background-color: rgba(39, 174, 96, 0.1);
  color: var(--color-success);
}

.statusFailed {
  background-color: rgba(220, 38, 38, 0.1);
  color: var(--color-error);
}

.statusQueued {
  background-color: rgba(52, 152, 219, 0.1);
  color: var(--color-info);
}

.statusPending {
  background-color: rgba(241, 196, 15, 0.1);
  color: var(--color-warning);
}

.statusCancelled {
  background-color: var(--color-gray-100);
  color: var(--color-gray-600);
}

.statusScheduled {
  background-color: rgba(155, 89, 182, 0.1);
  color: var(--color-accent-gold);
}

.loading,
.error {
  padding: 2rem;
  text-align: center;
  color: var(--color-gray-600);
}

.error {
  color: var(--color-error);
}
