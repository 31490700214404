.accountSettingsPage {
  max-width: 800px;
  margin: 0 auto;
  padding: 32px;
}

.pageTitle {
  font-size: 32px;
  font-weight: 600;
  color: var(--text-color, #1a1a1a);
  margin-bottom: 32px;
}

.section {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dangerZone {
  composes: section;
  border: 1px solid var(--danger-color, #ff3b30);
  background-color: var(--danger-color-light, #fff5f5);
}

.sectionTitle {
  font-size: 24px;
  font-weight: 500;
  color: var(--text-color, #1a1a1a);
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color-secondary, #666);
}

.input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid var(--border-color, #e0e0e0);
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.2s ease;
}

.input:focus {
  outline: none;
  border-color: var(--primary-color, #007aff);
  box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.1);
}

.error {
  color: var(--danger-color, #ff3b30);
  padding: 12px;
  background-color: var(--danger-color-light, #fff5f5);
  border-radius: 8px;
  margin-bottom: 20px;
}

.success {
  color: var(--success-color, #34c759);
  padding: 12px;
  background-color: var(--success-color-light, #f0fff4);
  border-radius: 8px;
  margin-bottom: 20px;
}

.warningText {
  color: var(--danger-color, #ff3b30);
  margin-bottom: 16px;
  font-size: 14px;
}

.button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  background-color: #0056b3;
}

.deleteButton {
  background-color: #dc3545;
}

.deleteButton:hover {
  background-color: #c82333;
}
