.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  background-color: #f5f2e9; /* Warm off-white background */
}

.hero {
  text-align: center;
  margin-bottom: 4rem;
}

.heroTitle {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #2c5f2d; /* Deep green from header */
}

.heroSubtitle {
  font-size: 1.25rem;
  color: #4a4a4a;
  margin-bottom: 2rem;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.featureCard {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid rgba(44, 95, 45, 0.1);
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.featureCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(44, 95, 45, 0.2);
}

.featureHeader {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.featureIcon {
  color: #2c5f2d; /* Deep green from header */
  margin-right: 0.75rem;
}

.featureTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c5f2d; /* Deep green from header */
}

.featureDescription {
  color: #4a4a4a;
}

.comingSoonCard {
  overflow: hidden;
  border: 1px solid rgba(255, 215, 0, 0.3); /* Subtle gold border */
}

.comingSoonBadge {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #ffd700; /* Gold from header */
  color: #2c5f2d; /* Deep green text */
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: 600;
}

.howToUse {
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem;
  margin-bottom: 4rem;
  border: 1px solid rgba(44, 95, 45, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.sectionTitle {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  color: #2c5f2d; /* Deep green from header */
}

.content {
  max-width: 100%;
}

.introText {
  font-size: 1.125rem;
  margin-bottom: 2rem;
  color: #4a4a4a;
}

.usageGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.usageTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #2c5f2d; /* Deep green from header */
}

.usageItem {
  background: rgba(44, 95, 45, 0.03);
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(44, 95, 45, 0.1);
}

.exampleContainer {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid rgba(44, 95, 45, 0.1);
}

.exampleImage {
  border-radius: 0.5rem;
  max-width: 100%;
  margin-bottom: 1rem;
  border: 1px solid rgba(44, 95, 45, 0.1);
}

.imageCaption {
  text-align: center;
  color: #4a4a4a;
  font-size: 0.875rem;
}

.imageCaption a {
  color: #2c5f2d;
  text-decoration: none;
  border-bottom: 1px solid rgba(44, 95, 45, 0.3);
}

.imageCaption a:hover {
  color: #ffd700;
  border-bottom-color: #ffd700;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .heroTitle {
    font-size: 2rem;
  }

  .sectionTitle {
    font-size: 1.75rem;
  }

  .features,
  .usageGrid,
  .chartGrid {
    grid-template-columns: 1fr;
  }
}
