.container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.emptyState {
  text-align: center;
  padding: 48px;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  margin: 24px 0;
}

.emptyState p {
  color: var(--color-gray-600);
  font-size: 1.1em;
  margin: 0;
}

.deleteButton {
  margin: 16px;
}

.paidButton {
  margin-left: 12px;
}

.setupButton {
  margin: 12px;
}

.yearGroup {
  margin-bottom: 24px;
  padding: 16px;
  background-color: var(--color-gray-50);
  border-radius: 8px;
  border-left: 4px solid var(--color-info);
}

.yearGroup h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-gray-300);
}

.markAllPaidButton {
  margin-left: 16px;
}

.yearGroup ul {
  margin-top: 12px;
  padding-left: 20px;
}

.yearGroup li {
  margin-bottom: 8px;
  padding: 8px;
  background-color: var(--color-white);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.monthlyDetails {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.monthlyDetails span {
  white-space: nowrap;
}

.actionButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 100px;
}
