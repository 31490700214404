.character-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.character-form-container h1 {
  color: var(--color-primary-green);
  margin-bottom: 1.5rem;
}

.form-error-message {
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error-border);
  color: var(--color-error);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.form-tabs {
  display: flex;
  border-bottom: 1px solid var(--color-gray-300);
  margin-bottom: 2rem;
  overflow-x: auto;
}

.form-tab {
  padding: 0.75rem 1.5rem;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  font-weight: 500;
  color: var(--color-gray-600);
  cursor: pointer;
  white-space: nowrap;
}

.form-tab:hover {
  color: var(--color-primary-green);
}

.form-tab.active {
  border-bottom-color: var(--color-primary-green);
  color: var(--color-primary-green);
}

.character-form {
  position: relative;
  margin-bottom: 8px;
}

.form-panel {
  display: none;
}

.form-panel.active {
  display: block;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--color-gray-700);
}

.required {
  color: var(--color-error);
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: var(--color-primary-green);
  outline: none;
}

.form-group input.error,
.form-group textarea.error,
.form-group select.error {
  border-color: var(--color-error);
}

.field-error {
  color: var(--color-error);
  font-size: 0.85rem;
  margin-top: 0.3rem;
}

.array-input-group {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.array-input-group input {
  flex: 1;
}

.remove-btn {
  background-color: var(--color-gray-200);
  border: none;
  border-radius: 4px;
  padding: 0 0.5rem;
  color: var(--color-gray-700);
  cursor: pointer;
  transition: background-color 0.2s;
}

.remove-btn:hover {
  background-color: var(--color-error-light);
  color: var(--color-error);
}

.add-btn {
  background-color: var(--color-gray-200);
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  color: var(--color-gray-700);
  cursor: pointer;
  margin-top: 0.5rem;
  transition: background-color 0.2s;
}

.add-btn:hover {
  background-color: var(--color-gray-300);
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--color-gray-200);
}

.cancel-btn {
  background-color: var(--color-gray-200);
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  color: var(--color-gray-700);
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-btn:hover {
  background-color: var(--color-gray-300);
}

.submit-btn {
  background-color: var(--color-primary-green);
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  color: var(--color-white);
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-btn:hover {
  background-color: var(--color-secondary-green);
}

.submit-btn:disabled,
.cancel-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .character-form-container {
    padding: 1rem;
  }

  .form-tabs {
    margin-bottom: 1.5rem;
  }

  .form-tab {
    padding: 0.5rem 1rem;
  }
}
