.container {
  background-color: var(--color-gray-50);
  border: 1px solid var(--color-gray-200);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.filterRow {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  min-width: fit-content;
}

.filterRow:last-child {
  margin-bottom: 0;
}

.filterGroup {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 180px;
  max-width: 300px;
}

.filterLabel {
  font-size: 0.85rem;
  color: var(--color-gray-700);
  margin-bottom: 0.5rem;
}

.select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
  background-color: var(--color-white);
  color: var(--color-gray-800);
  font-size: 0.9rem;
}

.searchInputWrapper {
  display: flex;
}

.searchInput {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px 0 0 4px;
  font-size: 0.9rem;
}

.searchButton {
  padding: 0.5rem 1rem;
  background-color: var(--color-primary-green);
  color: var(--color-white);
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.searchButton:hover {
  background-color: var(--color-secondary-green);
}

.dateInputs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dateInput {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  font-size: 0.9rem;
}

.dateSeparator {
  color: var(--color-gray-500);
  font-size: 0.9rem;
}

.applyButton {
  padding: 0.5rem 1rem;
  background-color: var(--color-primary-green);
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.applyButton:hover {
  background-color: var(--color-secondary-green);
}

.resetButton {
  padding: 0.5rem 1rem;
  background-color: var(--color-white);
  color: var(--color-gray-700);
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: auto;
}

.resetButton:hover {
  background-color: var(--color-gray-100);
  border-color: var(--color-gray-400);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .filterGroup {
    min-width: 100%;
  }

  .dateInputs {
    flex-wrap: wrap;
  }
}
