.location-detail-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
}

.location-detail-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--color-gray-200);
}

.header-content {
  flex: 1;
}

.header-content h1 {
  color: var(--color-primary-green);
  margin: 0 0 0.5rem 0;
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.detail-content {
  display: grid;
  gap: 2rem;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.info-item {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid var(--color-gray-200);
}

.info-item h3 {
  color: var(--color-gray-600);
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0 0 0.5rem 0;
}

.info-item p {
  margin: 0;
  color: var(--color-gray-900);
  font-size: 1rem;
  line-height: 1.5;
}

.location-description {
  margin-bottom: 2rem;
  line-height: 1.6;
}

.section-title {
  color: var(--color-primary-green);
  margin: 2rem 0 1rem;
  font-size: 1.25rem;
}

.section-content {
  line-height: 1.6;
  color: var(--color-gray-900);
}

.map-reference {
  margin-top: 1rem;
}

.map-reference img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: var(--color-gray-600);
}

.error-message {
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error-border);
  color: var(--color-error);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.item-type-badge {
  display: inline-block;
  padding: 0.3rem 0.75rem;
  background-color: var(--color-primary-green);
  color: white;
  border-radius: 16px;
  font-size: 0.85rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .location-detail-container {
    padding: 1rem;
  }

  .location-detail-header {
    flex-direction: column;
    gap: 1rem;
  }

  .header-actions {
    width: 100%;
    justify-content: flex-start;
  }

  .info-grid {
    grid-template-columns: 1fr;
  }
}
