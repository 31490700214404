.infoSection {
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.infoHeader {
  padding: 0.75rem 1rem;
  cursor: pointer;
  user-select: none;
}

.infoHeader:hover {
  background-color: #f0f1f2;
}

.infoTitle {
  font-weight: 500;
  color: #505050;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoToggle {
  font-size: 0.8rem;
  color: #707070;
}

.infoContent {
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
  line-height: 1.5;
  color: #404040;
}
