.errorContainer {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #f87171;
  border-radius: 0.375rem;
  background-color: #fee2e2;
  color: #b91c1c;
}

.icon {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
}

.message {
  font-size: 0.875rem;
}

.bold {
  font-weight: 600;
}
