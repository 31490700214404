.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
}

.content {
  margin-bottom: 20px;
}

.title {
  margin: 0 0 1rem 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.description {
  margin: 0 0 1.5rem 0;
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.cancelButton {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--color-gray-50);
  border: 1px solid var(--color-gray-300);
  color: #333;
}

.cancelButton:hover {
  background-color: var(--color-gray-200);
}

.confirmButton {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--color-error);
  border: none;
  color: white;
  transition: background-color 0.2s;
}

.confirmButton:hover:not(:disabled) {
  background-color: var(--color-error-dark);
}

.confirmButton:disabled {
  background-color: var(--color-error-light);
  cursor: not-allowed;
}
