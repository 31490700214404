.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.backLink {
  display: inline-block;
  margin-bottom: 1rem;
  color: #0066cc;
  text-decoration: none;
}

.backLink:hover {
  text-decoration: underline;
}

.title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #333;
}

.errorMessage {
  color: #d32f2f;
  margin-bottom: 1rem;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 1000px;
  max-width: 100%;
  min-height: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.sectionTitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.evaluationSections {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.evaluationItem {
  margin-bottom: 0.5rem;
  word-wrap: break-word;
}

.pendingEvaluation,
.pendingAnalysis {
  text-align: center;
  color: #666;
  margin: 2rem 0;
  font-style: italic;
}

.noEvaluation,
.noAnalysis {
  text-align: center;
  margin: 2rem 0;
}

/* Standardized button styles */
.evaluationButton,
.analysisButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.evaluationButton:hover,
.analysisButton:hover {
  background-color: #45a049;
}

.evaluationButton:disabled,
.analysisButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .card {
    width: 100%;
    min-height: auto;
    max-height: calc(100vh - 200px);
    padding-right: 4px;
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.selectors {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.versionSelector {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.versionSelector label {
  margin-right: 0.5rem;
  font-weight: bold;
}

.versionSelector select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  background-color: white;
}

.versionSelector select:focus {
  outline: none;
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
}

.modelSelector {
  margin-left: auto;
  margin-bottom: 1rem;
}

.modelSelector label {
  margin-left: 0.5rem;
  font-weight: bold;
}

.modelSelector select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  background-color: white;
}

.analysisSections {
  margin-top: 20px;
}

.sectionDescription {
  margin-bottom: 15px;
  font-style: italic;
}

.analysisContent {
  white-space: pre-wrap;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.analysisContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.analysisContent {
  white-space: pre-wrap;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-family: monospace;
}

/* NGram specific styles */
.ngramControls {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.ngramSelect {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.ngramSelect:focus {
  outline: none;
  border-color: #4caf50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.ngramTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.ngramTable th,
.ngramTable td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.ngramTable th {
  font-weight: bold;
  background-color: #f5f5f5;
}

.ngramChart {
  width: 100%;
  height: 300px;
  margin: 1rem 0;
}

.deleteSection {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}

.deleteWarning {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.deleteButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.deleteButton:hover {
  background-color: #c82333;
}

.deleteButton:disabled {
  background-color: #e9a8ae;
  cursor: not-allowed;
}

.successAlert {
  background-color: #d4edda;
  color: #155724;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  border: 1px solid #c3e6cb;
}

.successAlertTitle {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
}

.successAlertDescription {
  margin: 0;
  font-size: 0.9rem;
}

/* Alert Dialog Styles */
.alertDialogContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  z-index: 1000;
}

.alertDialogTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  color: #333;
}

.alertDialogDescription {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.5;
}

.alertDialogFooter {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.alertDialogCancel {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.alertDialogCancel:hover {
  background-color: #e9ecef;
}

.confirmDeleteButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.confirmDeleteButton:hover {
  background-color: #c82333;
}

/* Modal Backdrop */
.alertDialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
