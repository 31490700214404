.series-dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.series-dashboard-header {
  margin-bottom: 2rem;
}

.series-dashboard-header h1 {
  color: var(--color-primary-green);
  margin: 0 0 1rem 0;
  font-size: 2rem;
}

.series-dashboard-description {
  color: var(--color-gray-600);
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0;
  max-width: 800px;
}

.series-dashboard-actions {
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-start;
}

.create-series-btn {
  background-color: var(--color-primary-green);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-series-btn:hover {
  background-color: var(--color-secondary-green);
}

.series-dashboard-search {
  margin-bottom: 2rem;
}

.series-search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.series-search-input:focus {
  outline: none;
  border-color: var(--color-primary-green);
}

.series-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.series-card {
  background: white;
  border: 1px solid var(--color-gray-200);
  border-radius: 8px;
  padding: 1.5rem;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  cursor: pointer;
  margin-bottom: 8px;
}

.series-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.series-card h2 {
  color: var(--color-primary-green);
  margin: 0 0 1rem 0;
  font-size: 1.25rem;
}

.series-card p {
  color: var(--color-gray-600);
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.5;
}

.loading-indicator {
  text-align: center;
  color: var(--color-gray-600);
  padding: 2rem;
  font-size: 1.2rem;
}

.error-message {
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error-border);
  color: var(--color-error);
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
  margin: 2rem 0;
}

.empty-state {
  text-align: center;
  padding: 3rem;
  background-color: var(--color-gray-50);
  border-radius: 8px;
}

.empty-state h2 {
  color: var(--color-primary-green);
  margin-bottom: 1rem;
}

.empty-state p {
  color: var(--color-gray-600);
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .series-dashboard-container {
    padding: 1rem;
  }

  .series-dashboard-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .series-list {
    grid-template-columns: 1fr;
  }
}
