.fieldErrorContainer {
  position: relative;
  margin-top: 4px;
  padding-top: 8px;
}

.fieldErrorContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--color-error-light);
}

.fieldErrorContent {
  padding: 8px 12px;
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error-border);
  border-radius: 4px;
  font-size: 0.875rem;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: var(--color-error);
}

.fieldErrorMessage {
  color: var(--color-error);
  margin: 0;
}
