:root {
  /* Primary Brand Colors */
  --color-primary-green: #2c5f2d;
  --color-secondary-green: #2c5234;
  --color-accent-gold: #ffd700;
  --color-accent-gold-hover: #d4af37;

  /* Neutral Colors */
  --color-white: #ffffff;
  --color-cream: #f0f0e0;
  --color-gray-50: #f3f4f6;
  --color-gray-100: #f0f0f0;
  --color-gray-200: #e9ecef;
  --color-gray-300: #e5e7eb;
  --color-gray-400: #d1d5db;
  --color-gray-500: #bdc3c7;
  --color-gray-600: #6b7280;
  --color-gray-700: #4b5563;
  --color-gray-800: #2c3e50;

  /* Semantic Colors */
  --color-success: #27ae60;
  --color-success-dark: #065f46;
  --color-info: #3498db;
  --color-info-dark: #2980b9;
  --color-warning: #f1c40f;
  --color-error: #dc2626;
  --color-error-light: #fecaca;
  --color-error-border: #f87171;
  --color-link: #2563eb;
}
