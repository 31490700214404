.container {
  width: 100%;
  overflow-x: hidden;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

.statCard {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-gray-200);
  transition: transform 0.2s ease;
  min-width: 0; /* Prevents flex items from overflowing */
}

.statCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.statCard h3 {
  font-size: 0.9rem;
  color: var(--color-gray-600);
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statValue {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--color-gray-800);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.completed {
  color: var(--color-success);
}

.failed {
  color: var(--color-error);
}

.queued {
  color: var(--color-info);
}

.pending {
  color: var(--color-warning);
}

.chartsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-top: 1.5rem;
  width: 100%;
}

.chartWrapper {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-gray-200);
  min-width: 0; /* Prevents flex items from overflowing */
  overflow: hidden;
}

.chartWrapper h3 {
  font-size: 1rem;
  color: var(--color-gray-700);
  margin-bottom: 1rem;
  text-align: center;
}

.chart {
  height: 250px;
  position: relative;
  width: 100%;
}

.loading,
.error {
  padding: 2rem;
  text-align: center;
  background-color: var(--color-gray-50);
  border-radius: 8px;
  color: var(--color-gray-600);
}

.error {
  color: var(--color-error);
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .chartsContainer {
    grid-template-columns: 1fr;
  }

  .statsGrid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}

@media (max-width: 576px) {
  .statsGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .statValue {
    font-size: 1.5rem;
  }

  .chart {
    height: 200px;
  }
}
