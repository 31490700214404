.container {
  display: flex;
  flex-direction: column;
  height: 500px;
  background-color: var(--color-white);
  overflow-y: hidden;
}

.header {
  padding: 1rem;
  border-bottom: 1px solid var(--color-gray-200);
  background-color: var(--color-white);
}

.header h3 {
  margin: 0;
  color: var(--color-gray-700);
  font-size: 1.1rem;
}

.jobsList {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
  background-color: var(--color-white);
}

.jobItem {
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-200);
  cursor: pointer;
  transition: all 0.2s ease;
}

.jobItem:hover {
  border-color: var(--color-primary-green);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.selected {
  border-color: var(--color-primary-green);
  background-color: rgba(44, 95, 45, 0.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.jobHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.jobName {
  font-weight: 600;
  color: var(--color-gray-800);
}

.statusBadge {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.statusCompleted {
  background-color: rgba(39, 174, 96, 0.1);
  color: var(--color-success);
}

.statusFailed {
  background-color: rgba(220, 38, 38, 0.1);
  color: var(--color-error);
}

.statusBlocked {
  background-color: rgba(52, 152, 219, 0.1);
  color: var(--color-info);
}

.statusPending {
  background-color: rgba(241, 196, 15, 0.1);
  color: var(--color-warning);
}

.statusCancelled {
  background-color: var(--color-gray-100);
  color: var(--color-gray-600);
}

.statusScheduled {
  background-color: rgba(155, 89, 182, 0.1);
  color: #9b59b6;
}

.jobMeta {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: var(--color-gray-600);
}

.jobError {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: var(--color-error);
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid var(--color-gray-200);
}

.pageButton {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  color: var(--color-gray-800);
  cursor: pointer;
  transition: all 0.2s ease;
}

.pageButton:hover:not(:disabled) {
  background-color: var(--color-gray-100);
  border-color: var(--color-gray-400);
}

.pageButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pageInfo {
  font-size: 0.9rem;
  color: var(--color-gray-600);
}

.loading,
.error,
.empty {
  padding: 2rem;
  text-align: center;
  color: var(--color-gray-600);
}

.error {
  color: var(--color-error);
}

.empty {
  font-style: italic;
}
