.detailsList {
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
}

.detailsItem {
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.actionButtons {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.actionButton {
  padding: 0.5rem 1.5rem;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.actionButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.acceptButton {
  background-color: #22c55e;
  color: white;
}

.acceptButton:not(:disabled):hover {
  background-color: #16a34a;
}

.rejectButton {
  background-color: #ef4444;
  color: white;
}

.rejectButton:not(:disabled):hover {
  background-color: #dc2626;
}

.actionButton:focus {
  outline: 2px solid #3b82f6;
  outline-offset: 2px;
}
