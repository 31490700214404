.predefinedRoyalties {
  padding: 20px;
}

.bookInfo {
  margin-bottom: 20px;
}

.bookInfo label {
  display: block;
  margin-bottom: 10px;
}

.bookInfo input {
  width: 50%;
  padding: 6px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.royaltyTable {
  width: 100%;
  margin-top: 15px;
  border-collapse: collapse;
}

.royaltyTable th,
.royaltyTable td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.royaltyTable input {
  width: 90%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.saveButton {
  margin-top: 15px;
}

.checkboxLabel {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 8px;
  align-items: start;
  width: 100%;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
