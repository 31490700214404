.organizationManagement {
  margin-top: 30px;
}

.sectionTitle {
  font-size: 20px;
  margin-bottom: 20px;
}

.subsectionTitle {
  font-size: 18px;
  margin-bottom: 15px;
}

.section {
  margin-bottom: 30px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.formGroup {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.button:hover {
  background-color: #0056b3;
}

.acceptButton {
  background-color: #28a745;
}

.acceptButton:hover {
  background-color: #218838;
}

.rejectButton {
  background-color: #dc3545;
}

.rejectButton:hover {
  background-color: #c82333;
}

.inviteList {
  list-style-type: none;
  padding: 0;
}

.inviteItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 4px;
}

.buttonGroup {
  margin-left: 4px;
  display: flex;
  gap: 10px;
}

.error {
  color: #dc3545;
  margin-bottom: 15px;
}

.success {
  color: #28a745;
  margin-bottom: 15px;
}
